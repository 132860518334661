import {Injectable} from "@angular/core";
import {
  Action,
  createSelector,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateOperator,
  StateToken
} from "@ngxs/store";
import {patchLoading, RequestState, RequestStateSelectors, ResetErrors} from "./common";
import {Item} from "../api/model/item";
import {CheckoutHttpService} from "../api/api/checkout.http.service";
import {InsertProducts} from "./product-state-actions";
import {finalize, of} from "rxjs";
import {ShoppingCartSummary} from "../api/model/shoppingCartSummary";
import {doRequest} from "../services/util";

export type Cart = { [productId: string]: { [itemId: string]: number } };

export interface ShoppingCartStateModel extends RequestState {
  cart: Cart;
  summary?: Omit<ShoppingCartSummary, 'products'>
}

export class SetCartQuantity {
  static readonly type = '[Shopping Cart] Set item quantity';

  constructor(
    readonly item: Item,
    readonly quantity: number,
  ) {
  }
}

export class RemoveFromCart {
  static readonly type = '[Shopping Cart] Remove item from cart';

  constructor(
    readonly item: Item,
  ) {
  }
}

export const SHOPPING_CART_STATE_TOKEN = new StateToken<ShoppingCartStateModel>('shoppingCartState');

@State({
  name: SHOPPING_CART_STATE_TOKEN,
  defaults: {
    errorByRequest: {},
    loadingByRequest: {},
    cart: {},
  }
})
@Injectable()
export class ShoppingCartState implements NgxsOnInit {

  static readonly REQUESTS = new RequestStateSelectors(SHOPPING_CART_STATE_TOKEN)

  constructor(private readonly checkoutService: CheckoutHttpService) {
  }

  @Selector()
  static count(state: ShoppingCartStateModel) {
    return Object.values(state.cart).flatMap(p => Object.keys(p)).length
  }

  @Selector()
  static cart(state: ShoppingCartStateModel) {
    return state.cart;
  }

  @Selector()
  static summary(state: ShoppingCartStateModel) {
    return state.summary;
  }

  static isInCart(item: Item) {
    return createSelector([ShoppingCartState], (state: ShoppingCartStateModel) => {
      return (state.cart[item.productType]?.[item.id] || 0) > 0;
    });
  }

  ngxsOnInit(ctx: StateContext<ShoppingCartStateModel>): void {
    ctx.patchState({loadingByRequest: {}, errorByRequest: {}})
    ctx.setState(this.remove((_, quantity) => quantity > 0))
    const cart = ctx.getState().cart
    ctx.setState(patchLoading('cart', true))
    this.loadSummary(ctx, cart).pipe(
      finalize(() => ctx.setState(patchLoading('cart', false)))
    ).subscribe()
  }

  @Action(ResetErrors)
  resetErrors(ctx: StateContext<ShoppingCartStateModel>) {
    ctx.patchState({errorByRequest: {}})
  }

  @Action(SetCartQuantity)
  setCartQuantity(ctx: StateContext<ShoppingCartStateModel>, action: SetCartQuantity) {
    ctx.setState((state: Readonly<ShoppingCartStateModel>) => {
      const product = state.cart[action.item.productType] || {};
      return {
        ...state,
        cart: {
          ...state.cart,
          [action.item.productType]: {
            ...product,
            [action.item.id]: action.quantity,
          },
        }
      }
    })
    return this.loadSummary(ctx, ctx.getState().cart)
  }

  @Action(RemoveFromCart)
  removeFromCart(ctx: StateContext<ShoppingCartStateModel>, action: RemoveFromCart) {
    ctx.setState(this.remove((itemId, _) => itemId !== action.item.id))
    return this.loadSummary(ctx, ctx.getState().cart)
  }

  private loadSummary(ctx: StateContext<ShoppingCartStateModel>, cart: Cart) {
    if (Object.values(cart).flatMap(e => Object.keys(e)).length === 0) {
      ctx.setState(this.removeSummary())
      return of()
    }
    return doRequest({
      ctx,
      type: 'cart-summary',
      id: '',
      obs$: this.checkoutService.getSummary({shoppingCartSummaryRequest: {cart}}),
      next: summary => {
        const existingIds = summary.products.flatMap(p => p.items).map(i => i.id)
        ctx.setState(this.remove(itemId => existingIds.includes(itemId)))
        ctx.patchState({summary: {...{...summary, products: undefined}}})
        ctx.dispatch(new InsertProducts(summary.products))
      }
    })
  }

  private removeSummary(): StateOperator<ShoppingCartStateModel> {
    return (state: Readonly<ShoppingCartStateModel>) => ({...state, summary: undefined})
  }

  private remove(filter: (itemId: string, quantity: number) => boolean): StateOperator<ShoppingCartStateModel> {
    return (state: Readonly<ShoppingCartStateModel>) => {
      return {
        ...state,
        cart: Object.fromEntries(
          Object.entries(state.cart)
            .map(([productId, items]) => [
              productId,
              Object.fromEntries(Object.entries(items).filter(([itemId, quantity]) => filter(itemId, quantity)))
            ])
            .filter(([_, items]) => Object.keys(items).length > 0)
        )
      }
    }
  }
}
